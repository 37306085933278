.assessment-list {
  .course-card-height {
    min-height: 15.56rem;
  }

  .generic-module-card {
    min-height: 7.8rem;
  }
  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: inherit;
  }
}
