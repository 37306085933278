// App Scss //
@import "../public/assets/scss/app.scss";

@import "../public/assets/scss/utils/variables";

.primary-text-color {
  color: $primary-color;
}

.primary-icon-color {
  color: $primary-color;
}

.primary-bg-color {
  background: $pale-red;
}

.filter-margin {
  margin-top: 2.5rem;
}

.invalid-date-picker-feedback {
  color: $danger-color !important;
  margin-top: 0.2rem;
}

.rdt_TableHeadRow {
  background-color: $white-smoke !important;
  font-size: 0.8rem;
  font-weight: 600;
  border-bottom: none !important;
}

.react-datepicker-popper {
  z-index: 9999;
}

.primary-checkbox {
  box-shadow: none !important;
  border-color: $primary-color !important;

  &:checked {
    background-color: $primary-color !important;
    box-shadow: 0 0 0.2rem $primary-color !important;
  }
}

.react-datepicker__day--highlighted {
  background-color: $color-fiord !important;
}

.custom-swal-button {
  border-radius: 0.5rem;
  background-color: $primary-color !important;
  font-size: 0.9rem;
  color: $white !important;
  font-weight: 500;
}

.sanitized-content {
  ul {
    list-style-type: unset;
    padding-left: unset;
    padding-left: revert;
  }
}

.rdt_TableRow {
  background-color: $white !important;
}

.rdt_TableRow:hover {
  outline-style: unset !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: $thin-light-gray;
}

* {
  scrollbar-width: thin;
}

.text-ellips-parent {
  display: grid;
  grid-template-columns: 1fr;
}

.text-ellips {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.css-1u9des2-indicatorSeparator {
  background-color: unset !important;
}

.css-tj5bde-Svg {
  color: $black;
  width: 1rem;
}

.css-1xc4tao-control {
  border: 1px solid $primary-color !important;
  box-shadow: none !important;
  min-height: 37px !important;
  border-radius: var(--bs-border-radius) !important;
}

.rbt-input-multi.focus {
  box-shadow: none !important;
}
.css-13cymwt-control {
  border-radius: var(--bs-border-radius) !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none !important;
}

.rbt-input-multi .rbt-input-wrapper {
  margin-bottom: -3px !important;
}

.css-13cymwt-control {
  min-height: 37px !important;
}

.form-select {
  font-size: 0.875rem;
  line-height: 1.7;
  color: var(--text-gray);
}
