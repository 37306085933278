@import "../../../public/assets/scss/utils/variables";

.course-description {
  background-color: $primary-color;
  padding: 1rem;
  border-radius: 1rem;

  &__title {
    font-size: 1.3rem;
    font-weight: 700;
    color: $white;
  }

  &__content {
    opacity: 0.7;
    color: $white;
    border-top: 0.0625rem solid $light-white;
  }

  &__course-value {
    color: $white;
    background-color: $light-shade-gray;
    border-radius: 0.8rem;
    padding: 0.2rem 0.4rem;
  }

  &__devider {
    border: 1px solid $light-white;
    height: 1rem;
    opacity: 0.7;

    &:last-child {
      display: none;
    }
  }

  &__date-wrapper {
    color: $white;
    opacity: 0.8;
    margin-top: 1rem;
  }
}
