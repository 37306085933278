.lo-cell {
  width: 20%;
}

.assessment-duration-cell {
  width: 20%;
}

.modules-cell {
  width: 10%;
}
