.divider-container {
  display: flex;
  align-items: center;
}

.divider {
  border-left: 0.1rem solid #e0dede;
  height: 1.125rem;
  margin-right: 0.625rem;
}

.custom-icon-size {
  width: 1rem;
  height: 1rem;
}

.course-card-height {
  height: 14.56rem;
}

.request-course-card-responsive-height {
  height: 12.8rem;
}

.request-course-card-height {
  height: 11rem;
}

.card-bottom-section {
  margin-top: auto;
  width: 100%;
}
