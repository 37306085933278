@import "../../../../public/assets/scss/utils/variables";

.ilp-curriculum {
  .active {
    color: $white;
    background-color: $primary-color !important;
    border-radius: 0.4rem;
  }

  &__list {
    border-bottom: 1px solid $light-background;
    padding-bottom: 1rem;
    &:last-child {
      border-bottom: none;
    }
  }
  .rdt_TableRow {
    cursor: pointer;
  }
}
