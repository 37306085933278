@import "../../../../public/assets/scss/utils/variables";

.custom-skillUpdate-Bar {
  background-color: $black;
}

.custom-skillUpdate-ProgressBar {
  width: 15rem;
  height: 0.7rem;
}

.custom-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-accordion-header-responsive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.custom-skillUpdate-heading {
  width: 100%;
}

.custom-skillUpdate-progressWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
}

.custom-skillUpdate-progressWrapper-responsive {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
