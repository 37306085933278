@import "../../../public/assets/scss/utils/variables";

.custom-certifcation-card {
  border: 1px solid $sidebar-font;
}

.custom-header-col {
  padding-left: 0 !important;
}

.custom-svg-icon {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

.custom-certifiedOn {
  color: $light-text;
  display: flex;
  gap: 0.5rem;
}

.custom-heading {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-width: 11rem;
  font-weight: 500;
}

.custom-expired {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.5rem;
  background-color: $warm-yellow;
  color: $black;
}

.custom-re-certification {
  border-radius: 0.3rem;
  background-color: $semi-transparent-red-light;

  &:hover {
    background-color: $semi-transparent-red;
  }
}
