.assessment-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8;
}

.learning-assessment {
  padding: 7rem 0 2rem 0;
}
