.time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 100%;

  .time-inputs {
    display: flex;
    align-items: center;
    justify-content: center;

    .separator {
      margin: 0 10px;
      font-size: 20px;
    }
  }
}

.time-input {
  display: flex;
  flex-direction: column;
  align-items: center;

  .time-value {
    width: 30px;
    text-align: center;
    font-size: 16px;
    border: none;
    outline: none;
  }

  .up-arrow,
  .down-arrow {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #ee1b24;

    &:hover {
      color: #ee1b24;
    }
  }
}
