.custom-accordion .accordion-item {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.custom-accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.custom-accordion-border {
  border-radius: 0.625rem;
}

.cutsom-accordion-header {
  width: 100%;
}

.custom-accordion-time {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 0 0;
}

.custom-accordion-dropdown {
  margin-right: 1rem;
}
