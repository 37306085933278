@import "../../../../../public/assets/scss/utils/variables";

.user-profile-info {
  .profile-user-detail {
    display: flex;
    flex-direction: column;
    margin: 0 1.5rem 2rem;
  }

  .text-gray {
    color: $color-fiord;
  }

  span {
    color: $black;
  }

  .user-profile-name {
    margin-left: 1.5rem;
  }

  .profile-divider {
    margin-left: 0.1rem;
  }

  .divider-profile-custom-line {
    gap: 0.625rem;
    width: 100%;
    border-bottom: 0.0625rem solid var(--chart-dashed-border);
    cursor: pointer;
    margin-bottom: 0.8rem;
    margin-top: 0.2rem;
  }

  @media (max-width: 576px) {
    .profile-user-detail {
      margin-bottom: 10px;
    }
  }
}
