.footer-gmmco {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4rem;
  z-index: 2;

  .footer-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    &-start {
      margin-left: 18rem;
    }
  }
}
