@import "../../../../public/assets/scss/utils/variables";

.custom-asset-badge {
  background-color: $light-semi-gray !important;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.asset-list {
  &__badge {
    font-size: 0.8rem;
  }

  .total-card-body {
    display: grid !important;
    grid-template-columns: 65% 35%;
  }

  .asset-content-title {
    width: 100%;
    font-size: 1rem;
  }
  .total-count-icon-circle {
    width: 6rem;
  }
}
