.empty-folder-icon {
  width: 10.5rem;
  height: 7rem;
}

.custom-no-data-card {
  height: 25rem;
}

.custom-no-data-description {
  max-width: 35rem;
  text-align: center;
  margin: 0 auto;
}
