@import "../../../public/assets/scss/utils/variables";

.tabs-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  flex-shrink: 1;

  .badge-custom-radius {
    border-radius: 0.3rem;
  }

  .notification-badge {
    font-size: 0.5rem;
  }
}

#custom-bottom-tab {
  display: flex;
  flex-wrap: nowrap;
}

.nav-root {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.nav-border {
  flex-shrink: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.nav-border.active {
  font-weight: bold;
  border-bottom: 0.18rem solid $primary-color !important;
}

.notification-badge {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}
