@import "../../../public/assets/scss/utils/variables";

.custom-multi-progressBar {
  gap: 0.2rem;
  background-color: transparent;
  height: 0.5rem;
}

.custom-bar {
  border-radius: 0.2rem;
}

.percentage {
  position: absolute;
  margin-top: 3rem;
  color: $black;
  font-size: 0.7rem;
}

.success-color {
  background-color: $pb-success;
}

.warning-color {
  background-color: $pb-warning;
}

.error-color {
  background-color: $pb-error;
}

.info-color {
  background-color: $pb-info;
}

.assigned-color {
  background-color: $pb-assigned;
}
