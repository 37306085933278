@import "../../../../../../public/assets/scss/utils/variables";

.correct-label {
  color: green;
  font-weight: bold;
}

.wrong-label {
  color: red;
  font-weight: bold;
}

.review-assessment {
  .form-check-input:checked {
    border-color: $primary-color;
    background-color: $primary-color;
  }

  .form-check-input {
    border: 1px solid $primary-color;
  }
}
