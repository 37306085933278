.comment-section-margin {
  padding-right: 1.5rem !important;
}

.comments-wrapper {
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow: hidden;
}

.comments-section {
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem;
}

.comment-input-container {
  width: 100%;
  background-color: white;
  padding: 1rem;
  position: relative;
}
