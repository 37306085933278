@import "../../../public/assets/scss/utils/variables";

.custom-total-seat {
  background-color: $main-body-color !important;
  border: 0.1rem solid $light-gray;
  border-radius: 0.2rem;
}

.custom-total-card-font {
  color: $dark-color-light;
}
