@import "../../../public/assets/scss/utils/variables";

.user-profile-avatar img {
  width: 5rem;
  height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  border-radius: 50%;
  background-color: $white !important;
  margin-top: -40px;
}

.user-profile-cardheader {
  height: 6rem;
  border-radius: 1rem 1rem 0 0 !important;
  background-color: $light-dark !important;
}

.user-profile-hovercard {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}
