@import "../../../public/assets/scss/utils/variables";

.activity-wrapper {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;

  .activity-section {
    flex: 1;
    overflow-y: auto;
    padding-right: 1rem;

    &__inner-section {
      border-bottom: 1px solid $light-color;
      padding: 1rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid $light-color;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .activity-card {
    border: 1px solid $light-color;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
}
