@import "../../../public/assets/scss/utils/variables";

.employee-list {
  &__card {
    padding: 0.6rem 0.4rem;
    border-bottom: 1px solid $form-control-border-color;
  }
  &__card:last-child {
    border-bottom: none;
  }
  &__selected {
    background-color: $misty-rose;
    border-radius: 0.8rem;
  }
}
