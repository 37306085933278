@import "../../../public/assets/scss/utils/variables";

.justify-tabs-wrapper {
  .nav-justified .nav-item {
    flex: 0 1 auto;
    text-align: center;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-justified .nav-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    cursor: pointer;
  }

  .nav-margin {
    margin-left: 0.2rem;
  }

  .nav-right-margin {
    margin-right: 0.2rem;
  }

  .justify-tabs-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .justify-tabs-wrapper::-webkit-scrollbar {
    display: none;
  }

  .nav-justified {
    display: inline-flex;
    gap: 1rem;
    white-space: nowrap;
  }

  .nav-item {
    flex-shrink: 0;
  }

  .nav-link {
    text-align: center;
    padding: 1.2rem;
    min-width: 5rem;
  }
}
