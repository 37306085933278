@import "../../../public/assets/scss/utils/variables";

.year-navigator {
  .navigator-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $sidebar-font;
    border-radius: 50%;
    cursor: pointer;
  }

  .navigator-icon-circle {
    width: 2.5rem;
    height: 2.5rem;
  }

  .custom-year {
    padding: 0 1rem;
  }

  .custom-icon-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
