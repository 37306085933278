.ck.ck-editor__main > .ck-editor__editable {
  height: 6.25rem;
}

.ck.ck-content {
  ul {
    padding-left: revert;
  }
}

.ck-powered-by {
  display: none;
}
