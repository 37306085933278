.learning-sga {
  &__header {
    width: 100%;
  }

  .course-card-height {
    height: auto;
  }
  .generic-module-card {
    min-height: 7.7rem;
  }
}
