@import "../../../public/assets/scss/utils/variables";

.custom-master-accordion {
  border-radius: 1rem !important;
}
.custom-accordion-badge {
  background-color: $light-semi-gray !important;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
