.container-line {
  display: flex;
  align-items: center;
}

.divider-line {
  border-left: 0.1rem solid #e0dede !important;
  height: 3.125rem !important;
  margin-right: 0.625rem !important;
}
