@import "../../../../public/assets/scss/utils/variables";

.schedule-details {
  &__course-data {
    color: $medium-gray;
  }
  .learning-plan-course-card {
    margin-top: 0.5rem !important;
    &__container {
      width: 100%;
    }
  }
  .learningPlan-courseCard {
    height: auto !important;
    margin-bottom: 1rem;
    border: 1px solid $light-gray;
    box-shadow: unset;
  }
}
