.map-sga {
  .course-card-height {
    height: auto;
  }
  &__course-field {
    .rbt-token-remove-button {
      display: none !important;
    }
  }
}
