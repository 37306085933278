.imageContainer {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.comment-icon-container {
  position: relative;
  display: inline-block;
}

.red-dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0.3rem;
  right: -0.2rem;
}
