.answer-indicator-badge-custom {
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding: 0;
  border-radius: 25%;
  border: 1px solid transparent;
}

.answer-indicator-badge-custom.white-outline {
  border-color: gray;
}
