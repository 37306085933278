.load-factor-table {
  .rdt_TableHead {
    z-index: 2 !important;
  }

  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: inherit;
  }
}

.custom-circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.refresh-no-border {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
