@import "../../../public/assets/scss/utils/variables";

.course-activity {
  &__course {
    padding: 1.2rem;
    border: 1px solid $light-gray;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
}
