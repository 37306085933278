@import "../../../../../public/assets/scss/utils/variables";
.choose-option {
  background-color: $white;
  .title {
    font-size: 1rem;
    font-weight: 400;
    color: $black;
    margin-bottom: 0.625rem;
  }
}
