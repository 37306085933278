.question-selection-button {
  border-radius: 0.625rem;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0;
  border-color: transparent;

  &.outline-lightblue {
    border: 0.1rem solid lightblue;
    background-color: transparent;
  }
}

.question-selection-button2 {
  border-radius: 1rem;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0;
  border-color: transparent;

  &.outline-lightblue {
    border: 0.1rem solid lightblue;
    background-color: transparent;
  }
}
