@import "../../../../../public/assets/scss/utils/variables";

.free-choise {
  &__question {
    font-size: 1rem;
    color: $black;
  }

  &__title {
    font-size: 1.1rem;
    margin: 1rem 0 0.8rem;
    color: $black;
  }

  &__question-img {
    height: 18.75rem;
    width: 18.75rem;
  }
}
