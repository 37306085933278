@import "../../../public/assets/scss/utils/variables";

.custom-targetBar {
  background-color: $black;
}

.target-level-modal {
  &__header {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .custom-targetlevel-accordion {
    margin-bottom: 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid $horizontal-border-color;
    }
  }
}

.custom-targetProgressBar {
  height: 0.7rem;
}

.completed-icon-circle {
  width: 2.5rem;
  height: 1.6rem;
}

.custom-targetLevel-accordion {
  border: none !important;
}
.custom-targetLevel-accordion-content {
  width: 95%;
}

.custom-targetevel-accordion-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
