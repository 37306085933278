@import "../../../public/assets/scss/utils/variables";

.custom-eligible-badge {
  background-color: $light-gray !important;
}

.custom-participant-count {
  color: $dark-gray;
}

.custom-search-bar-large {
  width: 22rem;
  height: 2.8125rem;
}

.no-wrap-btn-text {
  white-space: nowrap;
}

.custom-padding-select-text {
  padding-right: 2rem;
}
