@import "../../../public/assets/scss/utils/variables";

.search-bar {
  .btn {
    padding: 0rem;
  }
}

.search-web {
  display: flex;
  align-items: flex-end;
  background-color: $light-color;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100%;
  max-width: 300px;
}

.search-input {
  border: none;
  outline: none;
  background: none;
  padding: 0.3rem;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  display: none;
  overflow: auto;
}

.form-control {
  &:focus {
    border-color: none;
    box-shadow: none;
    background-color: $light-color;
    color: none;
  }
}
