@import "../../../../../public/assets/scss/utils/variables";

.single-choise {
  &__question {
    font-size: 1rem;
    color: $black;
  }

  &__title {
    font-size: 1.1rem;
    margin: 1rem 0 0.8rem;
    color: $black;
  }

  &__question-img {
    height: 18.75rem;
    width: 18.75rem;
  }

  &__checkbox {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    border-bottom: 1px solid $light-semi-gray;
    background-color: $light-semi-gray;
    margin-top: 1rem;
    padding: 0.8rem;
    &:last-child {
      border-bottom: none;
    }
    &__label {
      margin-bottom: 0rem;
      color: $black;
      font-size: 1rem;
    }
  }
  .form-check-input {
    appearance: auto;
  }
}
