@import "../../../public/assets/scss/utils/variables";

.custom-range-slider-container {
  padding: 1.25rem;
  text-align: center;

  .custom-slider-track {
    height: 0.5rem;
    width: 100%;
    background: $pale-gray;
    border-radius: 0.4rem;

    .custom-slider-track-inner {
      height: 100%;
      background: $primary-color;
      border-radius: 0.5rem;
      position: absolute;
    }
  }

  .custom-slider-thumb {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: $primary-color;
    border: 0.2rem solid $white;
  }
}
