.timeline-wrapper {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
}

.timeline-section {
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem;
  width: 100%;
}
