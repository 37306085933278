@import "../../../public/assets/scss/utils/variables";

.trainer-dropdown {
  .custom-trainer-dropdownMenu {
    padding: 1rem;
    width: 32rem;
    max-height: 20rem;
    overflow-y: auto;
  }

  .trainer-dropdown-icon {
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 20%;
  }

  .trainer-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .trainer-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .custom-available-indicator {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.4rem;
        border-radius: 50%;
      }

      .status {
        padding: 0.2rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.85rem;

        &.available {
          background-color: $bg-available;
          color: $success-color;
        }

        &.not-available {
          background-color: $bg-not-available;
          color: $badge-light-color;
        }
      }
    }
  }
}
