@import "../../../public/assets/scss/utils/variables";

.nomination-list {
  .rdt_TableRow {
    font-size: 0.8rem;
  }
  .rdt_TableCell {
    font-size: 0.8rem;
  }

  .rdt_TableHeadRow {
    background-color: $white-smoke !important;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .table-input {
    font-size: 0.8rem;
  }

  .rdt_TableHead {
    z-index: 2 !important;
  }

  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: inherit;
  }
}
