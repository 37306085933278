.learningPlan-courseCard {
  height: 11rem;
}

.reduced-height-badge {
  padding: 0rem 0.375rem;

  height: auto;
}

.reduced-height-badge .d-flex {
  padding: 0;
  margin: 0;
}

.learningPlan-courseCard-footer {
  width: 100%;
}
