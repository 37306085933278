@import "../../../public/assets/scss/utils/variables";

.version-control {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: hidden;
  }
  &__section {
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .slider-modal {
    width: 21%;
    border-radius: unset;
  }

  .slider-modal-content {
    .close-button {
      top: 1.5rem;
    }
  }

  &__user-name {
    color: $card-header-color;
  }

  &__date {
    font-size: 1.5rem;
  }

  .accordion-body {
    padding: 0;
    .version-control__version-details {
      border-bottom: 1px solid $light-color;
      margin-bottom: 1rem;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
  .accordion {
    border-top: 1px solid $light-color;

    .custom-accordion-border {
      border: none;
      padding: 0 !important;
    }
    .accordion-button {
      padding: 1rem 0;
    }
  }
  &__current-version {
    .version-control__date {
      color: $primary-color;
    }

    .version-control__date-year {
      color: $primary-color;
    }
  }
  &__active-version {
    background-color: $pale-gray;
    padding: 0.5rem;
  }
  .accordion-button .svg-color {
    right: 0;
  }
}
