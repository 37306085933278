@import "../../../public/assets/scss/utils/variables";
.edit-details-button {
  background-color: $light-primary-color;
  color: $primary-color;
  padding: 8px 16px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
}

.edit-details-button:hover {
  background-color: $light-primary-color;
}

.basic-course-details-modal {
  .slider-modal-content {
    overflow-y: unset;
  }
}
