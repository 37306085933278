@import "../../../public/assets/scss/utils/variables";

.remedial-action {
  background-color: $pale-yellow;
  .card-header {
    background-color: $pale-yellow;
  }
  .border-tabs {
    .card-body {
      background-color: $pale-yellow;
    }
    .card {
      box-shadow: unset;
      margin-bottom: 1rem;
      :hover {
        box-shadow: unset;
      }
    }
    .active {
      background-color: unset;
    }
  }
}
