@import "../../../public/assets/scss/utils/variables";

.course-schedule-list {
  &__table {
    .rdt_TableCol:last-child,
    .rdt_TableCell:last-child {
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: inherit;
    }

    .rdt_TableCol:nth-last-child(2),
    .rdt_TableCell:nth-last-child(2) {
      position: sticky;
      right: 2rem;
      z-index: 1;
      background-color: inherit;
    }
  }

  .nomination_open {
    color: $bright_green;
  }
  .nomination_closed {
    color: $magenta_pink;
  }
  .course_completed {
    color: $lime-green;
  }
  .ongoing {
    color: $vivid_blue;
  }
  .cancelled,
  .tm_cancelled {
    color: $youtube-color;
  }
}
