@import "../../../public/assets/scss/utils/variables";

.assessment-details {
  padding: 7rem 0 3rem 0;
  &__question {
    font-size: 1rem;
    color: $black;
  }
  &__question-title {
    font-size: 1.12rem;
    font-weight: 500;
    color: $black;
  }
  &__question-wrapper {
    padding: 1.2rem;
    border: 1px solid $light-gray;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
}
