@import "../../../public/assets/scss/utils/variables";

.tag-input-container {
  border: 0.1rem solid $light-gray;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  padding: 0.3125rem;
  margin: 0.125rem;
  background: $light-danger;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  color: $primary-color;
}

.tag-remove {
  cursor: pointer;
  margin-left: 0.3125rem;
}

.tag-input {
  border: none;
  outline: none;
  box-shadow: none;
  flex: 1;
}
