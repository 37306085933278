@import "../../../public/assets/scss/utils/variables";

.user-list {
  &__card {
    padding: 0.6rem 0.4rem;
    border-bottom: 1px solid $form-control-border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  .custom-userList-search-bar {
    max-width: 100%;
  }

  .more-options {
    padding: 0 !important;
    margin-right: 1rem;
  }

  &__card-container {
    overflow-x: hidden !important;
  }
}
