@import "../../../public/assets/scss/utils/variables";

.participant-attendance-table {
  margin: 1rem;

  th {
    min-width: 11rem;
  }

  .attendance-table {
    th,
    td {
      text-align: center;
      vertical-align: middle;
    }

    .participant-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .username {
        color: $font-gray-color;
        font-size: 0.85rem;
      }
    }

    .status-dropdown {
      display: inline-block;

      .dropdown-toggle {
        font-weight: bold;
      }

      .dropdown-menu {
        text-align: center;
        min-width: auto;
      }
    }
  }

  .dropdown-present {
    background-color: $pale-gray-shade;

    option {
      background-color: $white;
    }
  }
  .dropdown-absent {
    background-color: $pink-shade;

    option {
      background-color: $white;
    }
  }
}
