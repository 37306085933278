@import "../../../public/assets/scss/utils/variables";

.custom-sga-progressBar {
  background-color: $black;
}

.custom-sgaProgressBar {
  width: 10rem;
  height: 0.7rem;
}

.reduced-height-badge {
  padding: 0rem 0.375rem;
  height: auto;
}

.reduced-height-badge .d-flex {
  padding: 0;
  margin: -0.0625rem;
}

.small-text {
  font-size: 0.75rem;
}
