@import "../../../public/assets/scss/utils/variables";

.module-card {
  background-color: $main-body-color;
  border-radius: 1rem;
  &__content {
    padding: 1rem;
  }

  &__module-name {
    font-weight: 500;
    font-size: 1rem;
  }
  &__module-score {
    color: $forest-green;
    font-weight: 600;
    font-size: 1rem;
  }
  &__score-wrapper {
    font-size: 1rem;
  }

  &__scrore-header {
    color: $medium-gray;
  }
}
