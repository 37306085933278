.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

.common-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
