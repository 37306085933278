@import "../../../public/assets/scss/utils/variables";

.generic-module-card {
  background-color: $generic-module-color;
  border-radius: 0.625rem;
}

.generic-module-card-margin {
  margin-left: 0.01rem;
  margin-bottom: 0.4rem;
}
