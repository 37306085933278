.course-summary-card {
  background-color: var(--light-background);
  padding: 1rem;
  border: 0.0625rem;
}

.course-summary-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.course-summary-body-mobile {
  flex-wrap: wrap;
  justify-content: space-between;
}

.course-summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 20%;
  margin-bottom: 1rem;
}

.course-summary-item-mobile {
  width: 45%;
}

.icon-circle {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 0.6rem;
}

.custom-count {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.custom-label-text {
  font-size: 1rem;
  font-weight: 500;
}

.vertical-divider {
  height: 100%;
  width: 0.0625rem;
  background-color: var(--normal-shadow);
  position: absolute;
  right: -1rem;
}

.vertical-divider-mobile {
  right: -0.5rem;
}
