.tabs-container {
  overflow-x: auto !important;
  overflow: hidden;
}

.ml-3 {
  margin-left: 1rem;
}

.course-schedule-details {
  .three-dots {
    padding-right: 0 !important;
  }
}
