.assessment-modal {
  &__header {
    font-size: 1.25rem;
    font-weight: 700;
  }
  &__exam {
    font-size: 1rem;
  }
  &__exam-name {
    font-weight: 700;
  }
}
