@import "../../../../public/assets/scss/utils/variables";

.add-curriculum {
  &__course {
    .rbt-token-remove-button {
      display: none !important;
    }
  }

  .cutsom-accordion-header {
    .span-class {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .custom-course-time {
      width: auto;

      .badge {
        background-color: $golden-yellow !important;
      }
    }
  }
}
