.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 9;
}

.overlay.open {
  opacity: 1;
  pointer-events: auto;
  overflow: hidden;
}

.slider-modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 36rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 9;
}

.slider-modal.open {
  transform: translateX(0);
}

.slider-modal-content {
  padding: 1.25rem;
  height: calc(100% - 2.5rem);
  overflow-y: auto;
  position: relative;
}

.close-button {
  background: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
}

.scroll-lock {
  overflow: hidden;
  height: 100%;
}
