@import "../../../public/assets/scss/utils/variables";

.module-question {
  border: 0.14rem solid $light-semi-gray;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 1rem;
    border-radius: 50%;
  }
}
