@import "../../../public/assets/scss/utils/variables";

.course-content-feedback {
  border: 0.1rem solid $light-semi-gray;
  background-color: $white;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 0 !important;

  .error-field {
    color: $danger_red;
  }

  &__tooltip-icon {
    svg {
      margin-top: 0.1rem !important;
    }
  }
}
