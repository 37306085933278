@import "../../../public/assets/scss/utils/variables";

.score-card {
  background-image: url(../../../public/assets/images/score.png);
  background-repeat: round;
  color: $white;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__content {
    display: flex;
    align-items: center;
    margin-left: 16%;
    gap: 3rem;
  }

  &__score {
    font-size: 5rem;
  }

  &__message {
    font-size: 1.25rem;
    margin-bottom: 0.6rem;
  }

  &__details {
    font-size: 0.875rem;
  }
}

.score-card-m {
  .score-card__score {
    font-size: 1rem;
  }
  .score-card__content {
    display: block;
  }
  .score-card__message {
    font-size: 1rem;
  }
}
