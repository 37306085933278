@import "../../../public/assets/scss/utils/variables";

.custom-progress-bar {
  background-color: $dark-yellow;
  height: 2.5rem;
}

.custom-expertise-level {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $semi-dark;
  font-size: 0.8rem;
}

.custom-progressBar {
  background-color: $warm-yellow;
  min-width: 2rem;
  color: $black;
}
