.badge-custom-radius {
  border-radius: 0.625rem;
}

.notification-badge {
  position: relative;
  right: -5px;
  top: -6px;
}

.users-notification-badge {
  position: relative;
  right: -5px;
  top: -6px;
  background-color: black !important;
}
