@import "../../../public/assets/scss/utils/variables";

.secondary-button {
  background-color: $light-primary-color !important;
  border-color: $light-primary-color !important;
  color: $primary-color;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: $light-primary-color !important;
    border-color: $light-primary-color !important;
    color: $primary-color !important;
  }
}
