.total-count-icon-circle {
  margin-top: 1rem;
  width: 5rem;
  height: 6rem;
}

.asset-header-content-wrapper {
  flex-wrap: wrap;
}

.asset-content-title {
  flex-shrink: 0;
}

.asset-content-side-title {
  width: 100%;
}
