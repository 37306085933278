@import "../../../public/assets/scss/utils/variables";

.date-range-display {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.5rem;
  background: $soft-pink;
  border-radius: 0.5rem;

  .date-text {
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.16px;
    line-height: 1.2rem;
    color: $black;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
