@import "../../../public/assets/scss/utils/variables";

.microsoft-signin-button {
  display: flex;
  height: 3.125rem;
  align-items: center;
  background-color: $white;
  justify-content: center;
  width: 100%;
  border: 0.063rem solid $sidebar-font;
  border-radius: 0.625rem;
  color: $black;
  padding: 0.625rem 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.microsoft-signin-button:hover {
  background-color: $light-dark;
  border: 0.063rem solid $sidebar-font;
}

.microsoft-logo {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.938rem;
}

.or-login-with {
  display: flex;
  align-items: center;
  color: $black;
}

.or-login-with::before,
.or-login-with::after {
  content: "";
  flex: 1;
  height: 0.063rem;
  background-color: $sidebar-font;
  margin: 0 0.625rem;
}
