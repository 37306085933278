@import "../../../../public/assets/scss/utils/variables";

.schedule-list {
  &__awaiting_trainer_approval {
    color: $charcoal_gray;
  }

  &__nomination_closed {
    color: $vivid_green;
  }

  &__nomination_open {
    color: $magenta_pink;
  }

  &__ongoing {
    color: $vivid_blue;
  }

  &__awaiting_rm_approval {
    color: $charcoal_gray;
  }

  .rdt_TableRow {
    font-size: 0.8rem;
    cursor: pointer;
  }
  .rdt_TableCell {
    font-size: 0.8rem;
  }

  .rdt_TableHeadRow {
    background-color: $white-smoke !important;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .table-input {
    font-size: 0.8rem;
  }

  .rdt_TableHead {
    z-index: 2 !important;
  }

  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: inherit;
  }
}
