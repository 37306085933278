@import "../../../public/assets/scss/utils/variables";

.nav-justified .nav-item {
  flex: 0 1 auto;
  text-align: center;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
}

.nav-justified .nav-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.nav-margin {
  margin-left: 0.2rem;
}

.nav-right-margin {
  margin-right: 0.2rem;
}
