@import "../../../public/assets/scss/utils/variables";

.options-container {
  .option-item {
    .option-number {
      width: 2rem;
      height: 2rem;
      border: 0.1rem solid $black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1rem;
      color: $black;
    }

    .input-container {
      flex-grow: 1;
      padding: 0 1rem 0 0;
    }
  }

  .add-option {
    color: $primary-color;
    font-size: 0.875rem;
  }
}
