@import "../../../../../public/assets/scss/utils/variables";

.dropdown-menu {
  margin-top: 0.6rem;
}

.custom-dropdown-menu {
  opacity: 1 !important;
}

.custom-dropdown-item {
  opacity: 1 !important;
  padding-left: 0 !important;
  border-radius: 0.2rem;
}

.dropdown-item-icon {
  margin-top: 0.3rem;
  color: $primary-color !important;
  fill: $primary-color !important;
  stroke: none !important;
  cursor: pointer;
}

.dropdown-item-text {
  color: $primary-color !important;
  fill: $primary-color !important;
  font:
    15px $font-outfit,
    $font-serif;
  cursor: pointer;
}

.custom-dropdown-profile {
  .dropdown-item {
    padding-left: 0 !important;
  }
}
